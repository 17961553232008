import * as React from "react"
import styled from 'styled-components'
import Menu from '../components/menu'
import Footer from '../components/footer'
import '../styles/typography.css'
import { StaticImage } from "gatsby-plugin-image"
import HelmetInfo from '../components/helmet-info'
import SubPageHero from '../components/sub-page-hero'
import noiseGif from '../images/noise.gif'
import squiggles from '../images/squiggles.svg'
import 'animate.css'
import image1 from '../images/01c.jpg'
import image2 from '../images/02c.jpg'
import image3 from '../images/03c.jpg'
import image4 from '../images/04c.jpg'

// Styled Components
const LandingSection = styled.section`
  display: grid;
  grid-template-columns: 1fr;
  max-width: 1200px;
  margin: 0 auto;

  @media (min-width:700px) { 
    grid-template-columns: 1fr 1fr;
  }
  

  h2 {
    font-size: 64px;
    line-height: 54px;
    margin-top: 108px;
    margin-bottom: 32px;
    text-align: center;

    @media (min-width:700px) { 
      text-align: left;
    }
  }

  p {
    font-size: 23px;
    line-height: 28px;
    margin-bottom: 32px;
    font-weight: 300;
    text-align: center;
    
    @media (min-width:700px) { 
      text-align: left;
    }

  }

  a { 
    display: inline-block;
    color: #505050;
    background: white;
    padding: 20px 30px;
    text-align: center;
    padding: 25px 62px;
    font-family: 'courier', sans-serif;
    border: 1px solid black;
    margin-bottom: 80px;
    margin-left: 20px;
    margin-right: 20px;
    text-transform: uppercase;
    transition: all 0.25s;
    position: relative;
  }

  a:hover, 
  a:focus {
    color: white;
    background: linear-gradient(160deg, rgba(255, 14, 229, 0.9), rgba(228, 63, 53, 0.9), rgba(255, 0, 0, 0.9)), url(${noiseGif}); 
  }

  /* Numbers  Colour */
  .num-1c {
    background: url(${image1}) no-repeat left top;
    background-size: 190px;
  }

  .num-2c {
    background: url(${image2}) no-repeat left top;
    background-size: 190px;
  }

  .num-3c {
    background: url(${image3}) no-repeat left top;
    background-size: 190px;
  }

  .num-4c {
    background: url(${image4}) no-repeat left top;
    background-size: 190px;
  }
  
`

const LandingVideo = styled.div`
  background: linear-gradient(160deg, rgba(0, 0, 0, 0.9), rgba(26, 26, 26, 0.9), rgba(0, 0, 0, 0.9)), url(${noiseGif});
  padding: 10px 10px 80px 10px;

  h1, h2 {
    font-size: 64px;
    line-height: 54px;
    margin-top: 108px;
    margin-bottom: 32px;
    text-align: center;
    color: white;
    line-height: 1.25;

    @media screen and (max-width: 900px) {
     font-size: 36px;
    }
  }

  iframe {
    margin: 0 auto;
    display: block;
  }

  .video-container-container {
    max-width: 1200px;
    margin: 0 auto;
  }

  .video-container {
    margin-top: 20px;
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 30px; height: 0; overflow: hidden;
    margin-bottom: 40px;
  }

  .video-container iframe,
  .video-container object,
  .video-container embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  } 
`

const HowWeWork = () => {
  return (
    <>
    <HelmetInfo /> 
    <Menu />
    <main>

    <SubPageHero>
            <h1 className="animate__animated animate__bounceInDown">Learn About Fizzpack</h1>
            <img className="hero-squiggles" src={squiggles} alt="decorative squiggles" />
    </SubPageHero>

      <LandingSection>
        <div className="landing-column">
          
          <h2>We make Animated Videos For you</h2>
          <p>We want to help you grow your business with smart informative animated video ads. If that is all you need to know contact us <strong>barry@fizzpack.com</strong> or..</p>
          <a href="#num-1">Scroll Down</a>
        
        </div>
        <div className="landing-column">
          <StaticImage src="../images/illustration-1.jpg" alt="" loading="eager" placeholder="blurred" />
        </div>
        
      </LandingSection>
    
      <LandingSection id="num-1">
        <div className="landing-column num-1c">
          
          <h2>Interested? Learn more about our method...</h2>
          <p>We start with a phone call or video conference or just plain old email and suss out what you need. </p>
          <a href="#num-2">Whats next?</a>
        
        </div>
        <div className="landing-column">
          <StaticImage src="../images/illustration-2.jpg" alt="" loading="lazy" placeholder="blurred"/>
        </div>
        
      </LandingSection>
      
      <LandingSection id="num-2">
        <div className="landing-column num-2c">
          
          <h2>We create a script</h2>
          <p>We’ll look at your business and come up with a script that details what you do and the value you bring to your customers.</p>
          <a href="#num-3">And then?</a>
        
        </div>
        <div className="landing-column">
          <StaticImage src="../images/illustration-3.jpg" alt="" loading="lazy" placeholder="blurred"/>
        </div>
        
      </LandingSection>
      
      <LandingSection id="num-3">
        <div className="landing-column num-3c">
          
          <h2>We design a storyboard</h2>
          <p>We’ll come up with some designs and create descriptive, styled images to detail each element of the script.</p>
          <a href="#num-4">And then?</a>
        
        </div>
        <div className="landing-column">
          <StaticImage src="../images/illustration-4.jpg" alt="" loading="lazy" placeholder="blurred"/>
        </div>
        
      </LandingSection>
      
      <LandingSection id="num-4">
        <div className="landing-column num-4c">
          
          <h2>We animate our designs</h2>
          <p>Each segment is then animated by us and set to captivating music and audio or text captions. </p>
          <a href="#further-info">What do I get?</a>
        
        </div>
        <div className="landing-column">
          <StaticImage src="../images/illustration-5.jpg" alt="" loading="lazy" placeholder="blurred"/>
        </div>
        
      </LandingSection>

      <LandingVideo>
        <h2 id="further-info" >You get a high quality visual marketing tool</h2>
        <div className="video-container-container">

            <div className="video-container">
               <iframe title="Fizzpack Reel" src="https://player.vimeo.com/video/447435118" width="640" height="360" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>
            </div>

        </div>

        <h2 className="email-note">Get in touch:<br /><strong>barry@fizzpack.com</strong></h2>

      </LandingVideo>
    </main>
    <Footer />       
  
    </>
  )
}

export default HowWeWork
